import { NavLink, useNavigate } from "react-router-dom";
import {sweetAlert} from '../Helpers/SweetAlert';

function Sidebar({ blog, nameClass }) {
  
  const navigate = useNavigate();

  const {errorAlert} = sweetAlert();

  const handleRedirectToSearch = (e) => {
    e.preventDefault();

    if(e.target.search.value.trim() !== ""){
      navigate(`/blog/searching/${e.target.search.value}`)
      e.target.reset();
    } else {
      errorAlert("Error","No se puede realizar una busqueda vacia");
    }

  };


  return (
    <>
      <aside className={"sidebar ".concat(nameClass)}>
        {
          blog && (
          <div className="nav-blog sidebar-item">
            <h3> Puedes hacer esto </h3>
            <NavLink to={'/blog/create'} className="btn btn-success">
              {" "}
              Crear articulo
              {" "}
            </NavLink>
          </div>
          )
        }

        <div className="search sidebar-item">
          <h3> Buscador </h3>
          <p>Encuentra el articulo que gustas</p>
          <form onSubmit={handleRedirectToSearch}>
            <input
              type="text"
              name="search"
              placeholder="Ej: Inteligencia"
            />
            <input
              type="submit"
              name="submit"
              value="Buscar"
              className="btn btn-submit"
            />
          </form>
        </div>
      </aside>
    </>
  );
}

export default Sidebar;
