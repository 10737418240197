import Slider from "./Slider";
import Sidebar from "./Sidebar";
import Articles from "./Articles";

function Blog() {
  return (
    <div>
      <Slider 
          nameClass=" slider-small"
          title = "Blog"
          link=""
          titlelink=""
      />

        <div className="container">
          <div className="row">          
              <Sidebar 
                  blog={true}
                  nameClass="col-12 col-md-4"
              />

              <section className="content col-12 col-md-8">
                <h2 className="subheader text-center mt-4"> Todos los articulos </h2>
                <Articles />
                
              </section>
          </div>
      </div>
    </div>
  );
}

export default Blog;
