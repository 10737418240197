import {useState, useEffect} from 'react'
import {Navigate, useNavigate, useParams} from 'react-router-dom';
import {sweetAlert} from '../Helpers/SweetAlert.js';
import Sidebar from './Sidebar';
import { useGet_Request } from '../Hooks/useGet_Reuqest.js';
import {requestPut} from '../Helpers/RequestPut';
import { requestPost } from '../Helpers/RequestPost.js';

function EditArticle() {

    const {id: idArticle} = useParams();

    const navigate = useNavigate();

    const [dataFormCreateArticle, setDataFormCreateArticle] = useState({
        id_article: idArticle
    });
    const [imageFile, setImageFile] = useState({});
    const [successCreateArticle, setSuccessCreateArticle] = useState('')

    const {data: getArticle} = useGet_Request('search_article_by_id&id_article='.concat(idArticle));
    
    const {successAlert} = sweetAlert();

    useEffect(() => {
        // console.log(getArticle)
        if (getArticle !== undefined && getArticle !== null) {
            getArticle.map( (data) => {
                setDataFormCreateArticle(data);
            })
        }
    }, [getArticle]);

    const submitCreateArticle = (e) => {
        e.preventDefault();
        // console.log(dataFormCreateArticle)
        // console.log(imageFile)
        const formData = new FormData();
        formData.append('article_info', JSON.stringify(dataFormCreateArticle));
        requestPost('update_article', formData, imageFile)
            .then (response=>{
                successAlert('Actualizado!', 'Buen trabajo, has actualizado el artículo!.');
                
                navigate('/blog');
            })
            .catch(err=> {
                console.log(err)
            })
        
    }

    const onChangeDataCreateArticle = ({target}) => {
        const {name, value} = target;

        setDataFormCreateArticle( dt => {
            return { 
                ...dt,
                [name]: value
            }
        } );
    }

    const onChangeFile = (e) => {
        console.log(e)
        setImageFile(e.target.files[0])
    }
 
    
    return (
        <div className='container'>
            <div className="row">
                <section className="content col-12 col-md-8">
                    <h1 className="subheader mt-4"> Editar Articulo </h1>
                    <form className="mid-form" onSubmit={submitCreateArticle} >
                        <div className="form-group">
                            <label htmlFor="title">Titulo:</label>
                            <input 
                                type="text" 
                                name="title" 
                                id="title"
                                defaultValue={dataFormCreateArticle?.title}
                                placeholder="Ej: Lenguajes TOP" 
                                onChange={onChangeDataCreateArticle}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="content">Contenido:</label>
                            <textarea 
                                name="content" 
                                id="content" 
                                defaultValue={dataFormCreateArticle?.content}
                                placeholder="Ej: Lenguajes TOP" 
                                className="" 
                                onChange={onChangeDataCreateArticle} 
                            ></textarea>
                        </div>
                        <div className="form-group">
                            <label htmlFor="file0">Imagen:</label>
                            <input 
                                type="file" 
                                name="file0" 
                                id="file0" 
                                placeholder="Ej: Lenguajes TOP" 
                                className="" 
                                onChange={onChangeFile} 
                            />
                        </div>
                        <input 
                            type="submit" 
                            name="submit" 
                            value="Guardar" 
                            className="btn btn-success" 
                            onChange={submitCreateArticle} 
                        />
                    </form>
                </section>
                <Sidebar nameClass='col-12 col-md-4 mb-4'/>
            </div>
        </div>
    )
}

export default EditArticle
