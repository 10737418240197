import React from "react";
import {useParams} from "react-router-dom";
import Slider from "./Slider";
import Sidebar from "./Sidebar";
import Articles from "./Articles";

function Search() {

    const {search} = useParams();
    console.log(search);
    
  return (
    <div>
      <Slider 
          nameClass=" slider-small"
          title = "Busqueda:"
          link=""
          titlelink=""
      />

        <div className="container">
          <div className="row">

            <Sidebar 
                blog={true}
                nameClass="col-12 col-md-4"
            />

            <section className="content col-12 col-md-8">
              <h2 className="subheader"> Ultimos articulos </h2>
              <Articles
                  searched={search}
              />
              {/* <Article /> */}

              {/* <div class="articles" id="articles">                
                  </div> */}
            </section>

          </div>
      </div>
    </div>
  );
}

export default Search;
