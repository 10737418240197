import {useState} from 'react'
import {useNavigate} from 'react-router-dom';
import {sweetAlert} from '../Helpers/SweetAlert.js';
import Sidebar from './Sidebar';
import {requestPost} from '../Helpers/RequestPost';

function CreatedArticle() {

    const navigate = useNavigate();

    const [dataFormCreateArticle, setDataFormCreateArticle] = useState({});
    const [imageFile, setImageFile] = useState({});
    const {successAlert, errorAlert} = sweetAlert();

    const submitCreateArticle = (e) => {
        e.preventDefault();
        
        if (dataFormCreateArticle.title !== undefined && dataFormCreateArticle.title !== '' && dataFormCreateArticle.content !== undefined && dataFormCreateArticle.content !== '' ) {
            const formData = new FormData();
            formData.append('article_info', JSON.stringify(dataFormCreateArticle));
    
            requestPost('add_article_to_blog', formData, imageFile)
                .then (response=>{
    
                    successAlert('Guardado!', 'Buen trabajo, has guardado un nuevo artículo!.');
                    navigate('/blog');
                })
        } else {
            errorAlert('Error', 'No se puede crear un artículo vacio, debe contener los campos requeridos.');
        }
        
    }

    

    const onChangeDataCreateArticle = ({target}) => {
        const {name, value} = target;

        setDataFormCreateArticle( dt => {
            return { 
                ...dt,
                [name]: value
            }
        } );
    }

    const onChangeFile = (e) => {
        console.log(e)
        setImageFile(e.target.files[0])
    }

    return (
        <>
            <div className="container">
                <div className='row'>
                    <section className="content col-12 col-md-8">
                        <h1 className="subheader m-0 m-sm-3"> Crear Articulo </h1>
                        <form className="mid-form" onSubmit={submitCreateArticle} >
                            <div className="form-group">
                                <label htmlFor="title">Titulo:</label>
                                <input 
                                    type="text" 
                                    name="title" 
                                    id="title" 
                                    placeholder="Ej: Lenguajes TOP" 
                                    onChange={onChangeDataCreateArticle}
                                    required
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="content">Contenido:</label>
                                <textarea 
                                    name="content" 
                                    id="content" 
                                    placeholder="Ej: Lenguajes TOP" 
                                    className="" 
                                    onChange={onChangeDataCreateArticle} 
                                    required
                                ></textarea>
                            </div>
                            <div className="form-group">
                                <label htmlFor="file0">Imagen:</label>
                                <input 
                                    type="file" 
                                    name="file0" 
                                    id="file0" 
                                    placeholder="Ej: Lenguajes TOP" 
                                    className="" 
                                    onChange={onChangeFile} 
                                />
                            </div>
                            <input 
                                type="submit" 
                                name="submit" 
                                value="Guardar" 
                                className="btn btn-success" 
                                onChange={submitCreateArticle} 
                            />
                        </form>
                    </section>
                    <Sidebar nameClass='col-12 col-md-4 mb-4'/>
                </div>
            </div>
        </>
    )
}

export default CreatedArticle
