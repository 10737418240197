import Home from '../components/Home';
import Blog from '../components/Blog';
import CreatedArticle from '../components/CreatedArticle';

export const navPath = [
    {
        to: '/',
        path: '/',
        name: 'Inicio',
        component: <Home/>
    },
    {
        to: '/blog',
        path: '/blog',
        name: 'Blog',
        component: <Blog />
    },
    {
        to: '/blog/create',
        path: '/blog/create',
        name: 'Crear Articulo',
        component: <CreatedArticle />
    }
    // {
    //     to: '/form',
    //     path: '/form',
    //     name: 'Formulario',
    //     component: <Formulario/>
    // },

]