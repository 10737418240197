import React from "react";

function Footer() {
  return (
    <footer className="footer">
      <div className="center">
        <p> &copy; Blog Programación Jaziel Isaí </p>
      </div>
    </footer>
  );
}

export default Footer;
