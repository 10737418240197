import { useState, useEffect } from "react";
import { useParams, Navigate, Link } from "react-router-dom";
import { useGet_Request } from "../Hooks/useGet_Reuqest";
import { requestDelete } from "../Helpers/RequestDelete";
import { sweetAlert } from "../Helpers/SweetAlert";
import Moment from "react-moment";
import "moment/locale/es";
import Sidebar from "./Sidebar";

import withoutPictures from "../assets/img/without_picture.png";


function Article() {
  const [getArticle, setGetArticle] = useState({});
  const [deleteArticle, setDeleteArticle] = useState(false);
  const { id: idArticle } = useParams();

  const { data: getArticleById } = useGet_Request(`search_article_by_id&id_article=${idArticle}`);
  const {confirmDialog} = sweetAlert();

  useEffect(() => {
    
    if (getArticleById !== undefined && getArticleById !== null) {
        getArticleById.map( (data) => {
          console.log(data);
          setGetArticle( data )
        } );    
    }

  }, [getArticleById, setGetArticle]);

  const handleClickUpdateArticle =()=>{
    
  }

  const handleClickDeleteArticle = () => {
    confirmDialog('¿Estas seguro?', '¿Deseas eliminar este artículo?', 'Eliminado', 'Artículo eliminado')
      .then((response)=>{
        if(response){
          requestDelete('article/'+idArticle)
            .then(response => {  
              setDeleteArticle(true);
                
            })
            .catch(err => {
              setDeleteArticle(false);
            });
        }
      })
      .catch(err => {
        setDeleteArticle(false);
      })
  }

  return (
    <>
      {
        deleteArticle &&
          <Navigate to='/blog' />
      }
      <div className="container">
        <div className="row">
          
          <section className="content col-12 col-md-8">
            {
              getArticle !== undefined && getArticle !== null && (
                <>
                  <article className="article-item article_detail">
                    <div className="article-img-wrap">
                      {
                        getArticle.img != null ? (
                            <img src={getArticle.img} alt={getArticle.title} />
                          ) : (
                            <img src={withoutPictures} alt="programdor" />
                          )
                      }
                    </div>
                    <div className="article_content">
                      <h1 className="subheader"> {getArticle.title} </h1>
                      <span className="article-date">
                        <Moment locale="es" fromNow>
                          {getArticle.date}
                        </Moment>
                      </span>
                      <p>{getArticle.content}</p>
                    </div>

                    <div className="article-btns">
                      <Link to={'/blog/edit/' + idArticle } className="btn btn-update" onClick={handleClickUpdateArticle}>
                        Editar
                      </Link>
                      <input type="button" value="Eliminar" className="btn btn-delete" onClick={handleClickDeleteArticle} />
                    </div>

                  </article>
                </>
              )
            }
          </section>
          <Sidebar 
              blog={false}
              nameClass="col-12 col-md-4 mb-4"
          />
        </div>
      </div>
    </>
  );
}

export default Article;
