import React from "react";
import Slider from "./Slider";
import Sidebar from "./Sidebar";
import Articles from "./Articles";

function Home() {
  return (
    <div>
      <Slider 
          nameClass=" slider-big"
          title = "Bienvenido a mi blog de programación"
          link="blog"
          titlelink="Ir al Blog"
      />

      <div className="container">
        <div className="row">

          <Sidebar nameClass="col-12 col-md-4"  />
          
          <section className="content col-md-8">
            <h2 className="subheader text-center mt-4"> Ultimos articulos </h2>

            <Articles 
              home={true}
            />

          </section>
        </div>


      </div>
    </div>
  );
}

export default Home;
