import React from "react";
import Logo from "../assets/img/LogoJaziel.svg";
import Navigation from "../Routes/Navigation";

function Header() {
  return (
    <header className="header">
      <div className="center">
        <div className="logo">
          <img
            src={Logo}
            alt="logotico"
            className="app-logo"
          />
          <span className="brand d-none d-md-block ">
            <strong>Ing. </strong>Sistemas Computacionales
          </span>
        </div>

        <Navigation />
        
      </div>
    </header>
  );
}

export default Header;
